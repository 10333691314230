<template>
  <div class="loginWarp">
    <div class="header">
      <div>{{isShowType==3?'重置密码':'账号密码登录'}}</div>
    </div>
    <div class="main">
      <div class="formBox">
        <van-form @submit="onSubmit">
          <van-cell-group inset class="group">
            <template v-if="isShowType==3">
              <van-field v-model="submitData.nwPassword" name="密码1"  placeholder="请输入新密码"
                :rules="rules.password"
                :formatter="trimLR"
                format-trigger="onBlur"
                :type="!password.show2?'password':'text'"
                :right-icon="password.show2?'eye-o':'closed-eye'"
                @click-right-icon="password.show2=!password.show2"/>
              <van-field v-model="submitData.confirmPassword" name="密码2"  placeholder="请重新输入新密码"
                :rules="rules.confirmPassword"
                :formatter="trimLR"
                format-trigger="onBlur"
                :type="!password.show3?'password':'text'"
                :right-icon="password.show3?'eye-o':'closed-eye'"
                @click-right-icon="password.show3=!password.show3"
                />
              <span class="tips">请输入包含数字、大小写字母和特殊符号，三种及以上组合的8-16位密码。</span>
            </template>
            <template v-else>
              <van-field v-model="submitData.username" name="用户名"  placeholder="请输入登录账号"
                :rules="rules.username"
                :formatter="trimLR"
                format-trigger="onBlur"
                />
              <van-field v-model="submitData.password"
              name="密码"  placeholder="请输入登录密码"
              :type="!password.show1?'password':'text'"
              :right-icon="password.show1?'eye-o':'closed-eye'"
              @click-right-icon="password.show1=!password.show1"
              :formatter="trimLR"
              format-trigger="onBlur"
              :rules="rules.username" />
            </template>
          </van-cell-group>

          <div class="submit">
            <van-button class="submitBtn" round block type="primary" native-type="submit">
              登录
            </van-button>
            <van-button v-if="isShowType==3" class="" round block  native-type="button" @click="onLast">
              上一步
            </van-button>
          </div>
        </van-form>
      </div>
      <div class="infoBox">
        <van-checkbox-group v-model="submitData.check" class="radio">
          <van-checkbox name="1" icon-size="14px">勾选即同意<span class="blue" @click="$way.goto('userAgreement')">《用户协议》</span>和<span class="blue" @click="$way.goto('privacyClause')">《隐私协议》</span></van-checkbox>
        </van-checkbox-group>
        <div class="gray">© 2021-2022 广州市传知汇科技有限公司版权所有</div>
      </div>
    </div>
    <Verify
      @success="verifySuccess"
      :mode="'pop'"
      :captchaType="cap_type"
      :imgSize="{ width: '330px', height: '155px' }"
      ref="verify"
    />
  </div>
</template>

<script>
import Verify from '@/components/verifition/Verify'
export default {
  name: 'loginWarp',
  props: [],
  components: {
    Verify
  },
  data () {
    var validateInput = (rule, value, callback) => {
      if (!this.$way.regSpecil(value)) {
        callback(new Error('不能输入特殊符号'))
      } else {
        callback()
      }
    }
    return {
      submitData: {},
      isShowType: 1, // 1 常规登录 2 短信认证登录 3 是否初次登录(重置密码) 4 找回密码 5 绑定账号 6 绑定手机号
      loginData: {},
      cap_type: '',
      password: {
        show1: false,
        show2: false,
        show3: false
      },
      rules: {
        username: [
          {
            validator: (value) => {
              console.log('object',value);
              if (/[\u4E00-\u9FA5]/g.test(value)) {
                return false // 不能输入中文
              } else {
              // 验证通过
                return true
              }
            },
            message: '不能输入中文',
            trigger: 'onBlur'
          }
        ],
        password: [
          // { validator: passworkValidator},
          { required: true },
          {
            validator:val=>{
              if (val.length>7&&val.length<17) {
                return true
              } else {
                return false
              }
            },
            message: '长度在 8 到 16 个字符',
            trigger: 'onBlur'
          },
          {
            validator: (value) => {
              console.log('object',value);
              if (/[\u4E00-\u9FA5]/g.test(value)) {
                return false // 不能输入中文
              } else {
              // 验证通过
                return true
              }
            },
            message: '不能输入中文',
            trigger: 'onBlur'
          },
          {
            validator: (value) => {
              console.log('object',value);
              if (/^(?![A-Za-z]+$)(?![A-Z\d]+$)(?![A-Z\W]+$)(?![a-z\d]+$)(?![a-z\W]+$)(?![\d\W]+$)\S{6,}$/.test(value)) {
                return true
              } else {
                return false
              }
            },
            message: '请输入包含数字、大小写字母和特殊符号，三种及以上组合。',
            trigger: 'onBlur'
          }
        ],
        confirmPassword: [
          // { validator: passworkValidator},
          { required: true },
          {
            validator:val=> {
              if (val==this.submitData.nwPassword) {
                return true
              } else {
                return false
              }
            },
            message: '密码填写不相同',
            trigger: 'onBlur'
          },
          {
            validator:val=>{
              if (val.length>7&&val.length<17) {
                return true
              } else {
                return false
              }
            },
            message: '长度在 8 到 16 个字符',
            trigger: 'onBlur'
          },
          {
            validator: (value) => {
              console.log('object',value);
              if (/[\u4E00-\u9FA5]/g.test(value)) {
                return false // 不能输入中文
              } else {
              // 验证通过
                return true
              }
            },
            message: '不能输入中文',
            trigger: 'onBlur'
          },
          {
            validator: (value) => {
              console.log('object',value);
              if (/^(?![A-Za-z]+$)(?![A-Z\d]+$)(?![A-Z\W]+$)(?![a-z\d]+$)(?![a-z\W]+$)(?![\d\W]+$)\S{6,}$/.test(value)) {
                return true
              } else {
                return false
              }
            },
            message: '请输入包含数字、大小写字母和特殊符号，三种及以上组合。',
            trigger: 'onBlur'
          },
        ]
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        // console.log(sessionStorage.getItem('loginsign'))
        // if (sessionStorage.getItem('loginsign') && Boolean(sessionStorage.getItem('loginsign'))==true) {
        //   this.isShowType = 3
        //   console.log(this.isShowType,'isShowType');
        // }
      }
    }
  },
  beforeCreate() {
  },
  methods: {
    onLast () {
      this.isShowType = 1
      this.submitData.nwPassword = null
      this.submitData.confirmPassword = null
    },
    trimLR (val) {
      // 格式空格
      // this.submitData.password = val.replace(/\s*/g, "")
      return val.replace(/\s*/g, '')
    },
    passworkValidator (val, rules) {
      console.log(val, 'val21', rules)

      if (!this.$way.regSpecil(val)) {
        return false
      } else {
        return false
      }
    },
    onSubmit () {
      console.log('object')
      if (!this.submitData.check) {
        return this.Info('请认真阅读并同意《用户协议》和《隐私政策》后登录')
      }
      if (this.isShowType == 3) {
        this.onResetPassword()
      } else {
        this.cap_type = 'clickWord'
        this.$refs.verify.show()
      }
    },
    // 登录流程未完成，本地缓存字段，如果登录页返回上个历史记录是网站内页，存在这个字段，自动退出，返回登录页
    sessionInStorageEvent () {
      sessionStorage.setItem('loginsign', true)
    },
    sessionEvent () {
      sessionStorage.setItem('token', this.loginData.access_token)
      sessionStorage.setItem('refresh_token', this.loginData.refresh_token)
      sessionStorage.setItem('loginData', JSON.stringify(this.loginData))
      sessionStorage.setItem('tenant_id', this.loginData.user_info.tenantId)
      sessionStorage.setItem('key', JSON.stringify({
        fIndex: 0
      }))
      const types = this.loginData.user_info.type
      if (this.isShowType == 3 && !this.$route.query.hasOwnProperty('code')) {
        // if(types == 2)
        //   this.judgeWXPhoneEvent()
        // else
        //   this.jumpEvent()
        this.$way.goto('loginHome')
      } else if (this.isShowType != 3 && !this.$route.query.hasOwnProperty('code')) {
        // if(types == 2)
        //   this.judgeWXPhoneEvent()
        // else
        //   this.jumpEvent()
        this.$way.goto('loginHome')
      } else if (this.$route.query.hasOwnProperty('code')) {
        // 微信登录
        if (this.loginData.user_info.firstLogin) { this.isShowType = 3 } else { this.judgeWXPhoneEvent() }
      }
    },
    // 获取当前学校信息
    getSchoolInfoList (user) {
      const hostname = location.hostname
      // let hostname = 'qii.gf5184.com';
      // http://nc.gf5184.com
      const href = hostname.split('.')[0]
      const params = {
        encoding: href || 'yhdx'
      }
      this.$request.getSchoolInfo(params).then((res) => {
        if (res.data.code == 0) {
          if (!sessionStorage.getItem('schoolInfo')) {
            sessionStorage.setItem('schoolInfo', JSON.stringify(res.data.data))
          }
          if (sessionStorage.getItem('tenant_id')!==res.data.data.id) {
            sessionStorage.setItem('tenant_id', res.data.data.id)
          }
          this.$request.loginYk(user, res.data.data.id, 'clickWord').then((res2) => {
            if (res2.status == '200') {
              if (res2.data.user_info.type == 1 || res2.data.user_info.type == 3) {
                this.loginData = res2.data
                this.Success('登录成功')
                if (res2.data.user_info.firstLogin) {
                  this.sessionInStorageEvent()
                  this.isShowType = 3
                  // this.getTitle();
                } else {
                  this.sessionEvent()
                }
              } else {
                return this.Info('登录账号不符，请下载【智汇学堂】APP进行学习')
              }
            }
          }).catch((err) => {
            console.log(err.response.data, 'err')
            this.Info(err.response.data.msg)
          })
        } else if (res.data.code == 1) {
        }
      }).catch((err) => { })
    },
    // 登录
    verifySuccess (params) {
      console.log(params, 'params')
      this.submitData.code = params.captchaVerification
      // sessionStorage.setItem('token',params.token)
      const user = this.$way.encryption({
        data: this.submitData,
        key: 'pigxpigxpigxpigx',
        param: ['password']
      })
      this.getSchoolInfoList(user)
    },
    // 重置密码
    onResetPassword () {
      const data = {
        nwPassword: this.submitData.nwPassword,
        confirmPassword: this.submitData.confirmPassword
      }
      this.$request.firstLogin(data, this.loginData.access_token, this.loginData.user_info.tenantId).then((res) => {
        if (res.data.code == 0) {
          this.clearEvent()
          // this.Success(res.data.data);
          // if(isBindPhone) {
          //   this.isShowType = 6
          //   return
          // }
          this.Info('重置成功')
          this.sessionEvent()
          // 判断本地存储是否存在loginsign,有则删掉
          if (sessionStorage.getItem('loginsign')) sessionStorage.removeItem('loginsign')
        }
      }).catch(err => {
      })
      // this.ruleForm, this.loginData.access_token, this.loginData.user_info.tenantId
    },
    clearEvent () {
      this.submitData.username = null
      this.submitData.password = null
    }
  }
}
</script>

<style lang='scss' scoped>
.loginWarp {
  .header {
    background: url("../assets/img/home/login-h.png") no-repeat;
    background-size: contain;
    font-size: 26px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 26px;
    background-size: contain;
    padding: 147px 40px 87px;
    div {

    }
  }

  .main {
    border-radius: 20px 20px 0px 0px;
    position: relative;
    top: -33px;
    background: #FFFFFF;
    .formBox {
      padding: 33px 40px;
      min-height: calc(100vh - 500px);
      .van-form {
        .van-cell-group {
          margin: 0;
          &::after{
            border: none;
          }
          .tips{
            display: block;
            margin-top: 16px;
            font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #999999;
line-height: 20px;
          }
          .van-field {
            padding-right: 0;
            padding-left: 0;
            &::after{
              left: 0;
            }
            van-field {

            }
          }
        }
      }

      .submit {
        .submitBtn {
          height: 44px;
background: #1A7EE2;
box-shadow: 0px 6px 10px 0px rgba(42,135,228,0.3);
border-radius: 30px;
margin-top: 30px;
margin-bottom: 24px;
border-color: #1A7EE2;
        }
      }
    }

    .infoBox {
    //   position: fixed;
    // bottom: 0;
    height: 55px;
    width: -webkit-fill-available;
    padding: 0 51px 64px 51px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 12px;
      .van-checkbox-group {
        margin: 0 0 16px 0;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 18px;
        .van-checkbox {
          span.blue {
            color: #1A7EE2;
            text-decoration: underline
          }
        }
      }

      .gray {

      }
    }
  }
}
</style>
